  .contact_usSection {
    margin-top:150px ;
  }

  .c_heading {
    text-transform:uppercase;
    background-image:linear-gradient(90deg, rgba(51,29,135,1) 18%, rgba(16,168,199,1) 100%);
     background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
     color:transparent;
  }