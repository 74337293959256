@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");


* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

.header {
  display: flex;
  justify-content: center;
  padding: 15px;
  background: rgb(51, 29, 135);
  background: linear-gradient(90deg, rgba(51, 29, 135, 1) 18%, rgba(16, 168, 199, 1) 100%);
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88%;
}

.logo {
  cursor: pointer;
}

.logo img {
  width: 90%;
  border-radius: 10px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav a {
  text-decoration: none;
  color: white;
  padding-bottom: 2px;
  font-size: small;
  text-align: center;
  margin-right: 50px;
  font-weight: 550;
  position: relative;
  cursor: pointer;
}

.nav a::before {
  position: absolute;
  content: '';
  top: 100%;
  left: 0;
  height: 2px;
  width: 0%;
  background: black;
  transition: all ease 0.4s;
}

.nav a:hover::before {
  width: 100%;
}


.button_63 {
  align-items: center;
  background-image: linear-gradient(144deg, #AF40FF, #5B42F3 50%, #00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  padding: 10px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
}

.button_63:active,
.button_63:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button_63 {
    font-size: 24px;
    min-width: 196px;
  }
}

.dropdown {
  position: absolute;
  top: 15%;
  left: 50%;
  background: #FFFFFF;
}

.header_banner {
  background: rgb(51, 29, 135);
  background: linear-gradient(90deg, rgba(51, 29, 135, 1) 18%, rgba(16, 168, 199, 1) 100%);
  height: 85vh;
  /* display: flex; */
}

.button_67 {
  align-items: center;
  background: #f5f5fa;
  border: 0;
  border-radius: 8px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  line-height: 1.5rem;
  padding: 10px;
  position: relative;
  text-align: left;
  transition: .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
  width: max-content;
  word-break: normal;
  word-spacing: normal;
}

.button_67:hover {
  background: #f8f8ff;
  box-shadow: rgba(243, 243, 244, 0.1) 0px 4px 16px, rgba(255, 255, 255, 0.05) 0px 8px 32px;
}

@media (min-width: 768px) {
  .button_67 {
    padding: 15px;
  }
}

.nav {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .nav {
    flex-direction: column;
  }
}