footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    color: white;
    width: 100%;
  }
  
  .social-media-icons a {
    margin: 0 10px;
    color: white;
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-media-icons a:hover {
    color: #0073e6;
  }
  