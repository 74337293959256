@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

.auto_slider_container{
  max-width: 95%;
  display: flex;
  margin: auto;
  overflow: hidden;
margin-top: 30px;
}

.auto_slider{
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 30s linear infinite;
}

.auto_slider li{
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  width: 100%;
  margin: 0px 15px;
}

@keyframes rotation {
 0% {
  transform:translateX(0)
 }
 100% {
  transform:translateX(-100%)
 }
}
.ourAlliancesSection {
  background: rgb(51,29,135);
  background: linear-gradient(90deg, rgba(51,29,135,1) 18%, rgba(16,168,199,1) 100%);
}