 *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 }
.text{
    text-transform:uppercase;
    margin:0 auto; 
    background-image:linear-gradient(90deg, rgba(51,29,135,1) 18%, rgba(16,168,199,1) 100%);
     background-clip: text;
    -moz-background-clip: text;
    -webkit-background-clip: text;
     color:transparent;
  }

  .scroll_to_top {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
  
  .scroll_to_top_button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    transition: background-color 0.3s;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .scroll_to_top_button:hover {
    background-color: #0056b3;
  }
  